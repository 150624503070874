<template>
  <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
    <div class="small-box bg-maroon-active pointer" v-on:click="$router.push('/finances')">
      <div class="inner" style="min-height: 104px;">
        <h3></h3>
        <h5>Финансы</h5>
      </div>
      <div class="icon">
        <i class="fas fa-donate"></i>
      </div>
      <a class="small-box-footer" title="Перейти" style="padding-top: 6px; padding-bottom: 6px;">
        <i class="fa font-scale fa-arrow-circle-right"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'incoming-calls-box',

  props: {

  }
}
</script>
